/*
 * Handler for confirmation init
 */

App.namespace.wrap('shared.confirmation', function () {
    $('[data-toggle=confirmation]').confirmation({
        singleton: true,
        popout: true,
        title: 'Wykonać?',
        btnOkLabel: "Tak",
        btnOkIcon: "glyphicon glyphicon-share-alt",
        btnOkClass: "btn-success btn-sm",
        btnCancelLabel: "Nie",
        btnCancelIcon: "glyphicon glyphicon-ban-circle",
        btnCancelClass: "btn-danger btn-sm",
        placement: 'auto'
    });

    $('[data-confirmation=show]').confirmation({
        singleton: true,
        popout: true,
        title: 'Wykonać?',
        btnOkLabel: "Tak",
        btnOkIcon: "glyphicon glyphicon-share-alt",
        btnOkClass: "btn-success btn-sm",
        btnCancelLabel: "Nie",
        btnCancelIcon: "glyphicon glyphicon-ban-circle",
        btnCancelClass: "btn-danger btn-sm",
        placement: 'auto'
    });
    $('[data-confirmation=click]').off('click');
    $('[data-confirmation=click]').on('click', function(evt) {
        evt.preventDefault();
        var link = $(this);
        var title = $(this).attr('data-confirmation-title');
        var message = $(this).attr('data-confirmation-message');
        bootbox
            .dialog({
                size: 'small',
                title: title,
                message: message,
                locale: 'pl',
                onEscape: true,
                backdrop: true,
                buttons: {
                    success: {
                        label: '<i class="glyphicon glyphicon-share-alt"></i>Tak',
                        className: "btn-success btn-sm",
                        callback: function() {
                            var attr = link.attr('type');
                            if(typeof attr !== typeof undefined && attr !== false &&  link.attr('type').length && link.attr('type')=='submit'){
                                link.closest('form').submit();
                            } else {
                                window.location = link.attr('href');
                            }
                        }
                    },
                    danger: {
                        label: '<i class="glyphicon glyphicon-ban-circle"></i>Nie',
                        className: "btn-danger btn-sm",
                        callback: function() {
                            //Example.show("uh oh, look out!");
                        }
                    }
                }
                /*callback: function(result){
                    if(result){
                        window.location = link.attr('href');
                    }
                }*/
            });

    });

});
