function OpenInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}
var Tools = {
    Images: {
        /**
         * ładowanie kolejno zdjęć
         */
        SequentialLoader: function() {
            var fnLoader = arguments.callee;
            var $el = $(this);
            var i = new Image();
            i.onload = function() {
                $el.find('img').attr('src', i.src);
                $el.next('li').each(fnLoader);
            };
            i.src = $el.find('img').attr('data-src');
        }

    },
    Videos: {
        /**
         * ładowanie kolejno video
         */
        SequentialLoader: function() {
            var fnLoader = arguments.callee;
            var $el = $(this);
            var i = new Image();
            i.onload = function() {
                $el.find('img').attr('src', i.src);
                $el.next('li').each(fnLoader);
            };
            i.src = $el.find('img').attr('data-src');
        }

    }
};

var myJsp;

/* DOM Ready Functions */
jQuery(function($) {
    if ($('body.designgallery').length > 0) {
        // Szerokość całego kontenera z elementami
        // *************************************************************************
        var setUlWidth = function(h) {
            var w = 0;
            $('#designgallerygrid .content-object.objects img').each(function() {
                var $el = $(this);

                var ow = $el.attr('data-owidth');
                var oh = $el.attr('data-oheight');
                var nh = h - 20;
                var nw = ow * (nh / oh) - 30;
                w += nw + 30;
                $el.css({
                    width: nw,
                    height: nh
                });

            });

            // +1 for firefox to prevent last image from breaking to next line
            var shim = 1; // $.browser.mozilla ? 1 : 0;
            $('#designgallerygrid .content-object.objects ul').width(w + shim).height(h);
            try {
                myJsp.reinitialise({
                    contentWidth: w
                });
            } catch (e) {}
        };

        // Zmiana rozmiaru kontenera z kontentem podczas zmiany wielkości okna
        // *************************************************************************
        var resizeContent = function() {

            var sidebarWidth = $('body.designgallery .content-object.left-menu').outerWidth(true);
            var minHeight = 440;
            var maxHeight = 1200;

            // max height dla iPad wynosi 670
            if (isMobile || navigator.platform.indexOf('iPad') != -1) {
                maxHeight = 670;
            }
            $('#designgallerygrid').hide();
            $('#designgallerygrid').css('width', $(document).width() - sidebarWidth - 15);
            //var h = $(window).height() - 30; // dodajemy 10px pod scrollbarerm
            //var h = $('body').height() - 30 - +$( '.main-header' ).outerHeight(true) - +$( '.navs' ).outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            var h = $(document).height() - 30 - +$('.main-header').outerHeight(true) - +$('.navs').outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            // Returns height of browser viewport
            $('#designgallerygrid').show();

            // Returns height of HTML document
            if (h > maxHeight) h = maxHeight;
            else if (h < minHeight) h = minHeight;
            //$('.jspPane,#itemList').height(h);

            $('.jspContainer,#designgallerygrid .content-object.objects').height(h + 20);
            if (myJsp) {
                var tmp = myJsp.getPercentScrolledX();
            }

            setUlWidth(h);
            positionFooter();

            if (myJsp) {
                myJsp.scrollToPercentX(tmp, false);
            }
        };

        // wypozycjonowanie stopki
        // *************************************************************************
        var positionFooter = function() {
            $('body.designgallery .content-object.left-menu').removeAttr('style');
            $('body.designgallery .content-object.left-menu li.bottom').removeAttr('style');
            if ($('body.designgallery .content-object.left-menu').height() < $('#designgallerygrid .content-object.objects').height()) {
                $('body.designgallery .content-object.left-menu').height($('#designgallerygrid .content-object.objects').height());
                var offset = 30;
                $('body.designgallery .content-object.left-menu li.bottom').css({
                    position: 'absolute',
                    top: $('body.designgallery .content-object.left-menu').height() - offset
                });
            } else {
                $('body.designgallery .content-object.left-menu').removeAttr('style');
                $('body.designgallery .content-object.left-menu li.bottom').removeAttr('style');
            }
        };

        // Inicjalizacja jScrollPane
        // *************************************************************************
        var jScroll = $('#designgallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        });
        myJsp = jScroll.data('jsp');
        jScroll.bind('mousewheel',function (event, delta, deltaY){
                myJsp.scrollByX(delta*-50);
                return false;
            }
        );

        // bind to window resize event
        // *************************************************************************
        var isMobile = typeof(isMobile) != 'undefined' ? isMobile : false;
        if (!isMobile) {
            $(window).unbind('resize', resizeContent).bind('resize', resizeContent).trigger('resize');
        }

        // Ladowanie zdjec
        // *************************************************************************
        $('#designgallerygrid .content-object.objects li:eq(0)').each(Tools.Images.SequentialLoader);
    }
    if ($('body.fotogallery').length > 0) {
        // Szerokość całego kontenera z elementami
        // *************************************************************************
        var setUlWidth = function(h) {
            var w = 0;
            $('#fotogallerygrid .content-object.objects img').each(function() {
                var $el = $(this);

                var ow = $el.attr('data-owidth');
                var oh = $el.attr('data-oheight');
                var nh = h - 20;
                var nw = ow * (nh / oh) - 30;
                w += nw + 30;
                $el.css({
                    width: nw,
                    height: nh
                });

            });

            // +1 for firefox to prevent last image from breaking to next line
            var shim = 1; // $.browser.mozilla ? 1 : 0;
            $('#fotogallerygrid .content-object.objects ul').width(w + shim).height(h);
            try {
                myJsp.reinitialise({
                    contentWidth: w
                });
            } catch (e) {}
        };

        // Zmiana rozmiaru kontenera z kontentem podczas zmiany wielkości okna
        // *************************************************************************
        var resizeContent = function() {

            var sidebarWidth = $('body.fotogallery .content-object.left-menu').outerWidth(true);
            var minHeight = 440;
            var maxHeight = 1200;

            // max height dla iPad wynosi 670
            if (isMobile || navigator.platform.indexOf('iPad') != -1) {
                maxHeight = 670;
            }
            $('#fotogallerygrid').hide();
            $('#fotogallerygrid').css('width', $(document).width() - sidebarWidth - 15);
            //var h = $(window).height() - 30; // dodajemy 10px pod scrollbarerm
            //var h = $('body').height() - 30 - +$( '.main-header' ).outerHeight(true) - +$( '.navs' ).outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            var h = $(document).height() - 30 - +$('.main-header').outerHeight(true) - +$('.navs').outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            // Returns height of browser viewport
            $('#fotogallerygrid').show();

            // Returns height of HTML document
            if (h > maxHeight) h = maxHeight;
            else if (h < minHeight) h = minHeight;
            //$('.jspPane,#itemList').height(h);

            $('.jspContainer,#fotogallerygrid .content-object.objects').height(h + 20);
            if (myJsp) {
                var tmp = myJsp.getPercentScrolledX();
            }

            setUlWidth(h);
            positionFooter();

            if (myJsp) {
                myJsp.scrollToPercentX(tmp, false);
            }
        };

        // wypozycjonowanie stopki
        // *************************************************************************
        var positionFooter = function() {
            $('body.fotogallery .content-object.left-menu').removeAttr('style');
            $('body.fotogallery .content-object.left-menu li.bottom').removeAttr('style');
            if ($('body.fotogallery .content-object.left-menu').height() < $('#fotogallerygrid .content-object.objects').height()) {
                $('body.fotogallery .content-object.left-menu').height($('#fotogallerygrid .content-object.objects').height());
                var offset = 30;
                $('body.fotogallery .content-object.left-menu li.bottom').css({
                    position: 'absolute',
                    top: $('body.fotogallery .content-object.left-menu').height() - offset
                });
            } else {
                $('body.fotogallery .content-object.left-menu').removeAttr('style');
                $('body.fotogallery .content-object.left-menu li.bottom').removeAttr('style');
            }
        };

        // Inicjalizacja jScrollPane
        // *************************************************************************

        /*myJsp = $('#fotogallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        }).data('jsp');*/
        var jScroll = $('#fotogallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        });
        myJsp = jScroll.data('jsp');
        jScroll.bind('mousewheel',function (event, delta, deltaY){
                myJsp.scrollByX(delta*-50);
                return false;
            }
        );

        // bind to window resize event
        // *************************************************************************
        var isMobile = typeof(isMobile) != 'undefined' ? isMobile : false;
        if (!isMobile) {
            $(window).unbind('resize', resizeContent).bind('resize', resizeContent).trigger('resize');
        }

        // Ladowanie zdjec
        // *************************************************************************
        $('#fotogallerygrid .content-object.objects li:eq(0)').each(Tools.Images.SequentialLoader);
    }

    if ($('body.obrazygallery').length > 0) {
        // Szerokość całego kontenera z elementami
        // *************************************************************************
        var setUlWidth = function(h) {
            var w = 0;
            $('#obrazygallerygrid .content-object.objects img').each(function() {
                var $el = $(this);

                var ow = $el.attr('data-owidth');
                var oh = $el.attr('data-oheight');
                var nh = h - 20;
                var nw = ow * (nh / oh) - 30;
                w += nw + 30;
                $el.css({
                    width: nw,
                    height: nh
                });

            });

            // +1 for firefox to prevent last image from breaking to next line
            var shim = 1; // $.browser.mozilla ? 1 : 0;
            $('#obrazygallerygrid .content-object.objects ul').width(w + shim).height(h);
            try {
                myJsp.reinitialise({
                    contentWidth: w
                });
            } catch (e) {}
        };

        // Zmiana rozmiaru kontenera z kontentem podczas zmiany wielkości okna
        // *************************************************************************
        var resizeContent = function() {

            var sidebarWidth = $('body.obrazygallery .content-object.left-menu').outerWidth(true);
            var minHeight = 440;
            var maxHeight = 1200;

            // max height dla iPad wynosi 670
            if (isMobile || navigator.platform.indexOf('iPad') != -1) {
                maxHeight = 670;
            }
            $('#obrazygallerygrid').hide();
            $('#obrazygallerygrid').css('width', $(document).width() - sidebarWidth - 15);
            //var h = $(window).height() - 30; // dodajemy 10px pod scrollbarerm
            //var h = $('body').height() - 30 - +$( '.main-header' ).outerHeight(true) - +$( '.navs' ).outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            var h = $(document).height() - 30 - +$('.main-header').outerHeight(true) - +$('.navs').outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            // Returns height of browser viewport
            $('#obrazygallerygrid').show();

            // Returns height of HTML document
            if (h > maxHeight) h = maxHeight;
            else if (h < minHeight) h = minHeight;
            //$('.jspPane,#itemList').height(h);

            $('.jspContainer,#obrazygallerygrid .content-object.objects').height(h + 20);
            if (myJsp) {
                var tmp = myJsp.getPercentScrolledX();
            }

            setUlWidth(h);
            positionFooter();

            if (myJsp) {
                myJsp.scrollToPercentX(tmp, false);
            }
        };

        // wypozycjonowanie stopki
        // *************************************************************************
        var positionFooter = function() {
            $('body.obrazygallery .content-object.left-menu').removeAttr('style');
            $('body.obrazygallery .content-object.left-menu li.bottom').removeAttr('style');
            if ($('body.obrazygallery .content-object.left-menu').height() < $('#obrazygallerygrid .content-object.objects').height()) {
                $('body.obrazygallery .content-object.left-menu').height($('#obrazygallerygrid .content-object.objects').height());
                var offset = 30;
                $('body.obrazygallery .content-object.left-menu li.bottom').css({
                    position: 'absolute',
                    top: $('body.obrazygallery .content-object.left-menu').height() - offset
                });
            } else {
                $('body.obrazygallery .content-object.left-menu').removeAttr('style');
                $('body.obrazygallery .content-object.left-menu li.bottom').removeAttr('style');
            }
        };

        // Inicjalizacja jScrollPane
        // *************************************************************************

        /*myJsp = $('#obrazygallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        }).data('jsp');*/
        var jScroll = $('#obrazygallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        });
        myJsp = jScroll.data('jsp');
        jScroll.bind('mousewheel',function (event, delta, deltaY){
                myJsp.scrollByX(delta*-50);
                return false;
            }
        );

        // bind to window resize event
        // *************************************************************************
        var isMobile = typeof(isMobile) != 'undefined' ? isMobile : false;
        if (!isMobile) {
            $(window).unbind('resize', resizeContent).bind('resize', resizeContent).trigger('resize');
        }

        // Ladowanie zdjec
        // *************************************************************************
        $('#obrazygallerygrid .content-object.objects li:eq(0)').each(Tools.Images.SequentialLoader);
    }

    if ($('body.videogallery').length > 0) {
        // Szerokość całego kontenera z elementami
        // *************************************************************************
        var setUlWidth = function(h) {
            var w = 0;
            $('#videogallerygrid .content-object.objects iframe').each(function() {
                var $el = $(this);

                var ow = $el.attr('data-owidth');
                var oh = $el.attr('data-oheight');
                var nh = h - 20;
                var nw = ow * (nh / oh) - 30;
                w += nw + 30;
                $el.css({
                    width: nw,
                    height: nh
                });

            });

            // +1 for firefox to prevent last image from breaking to next line
            var shim = 1; // $.browser.mozilla ? 1 : 0;
            $('#videogallerygrid .content-object.objects ul').width(w + shim).height(h);
            try {
                myJsp.reinitialise({
                    contentWidth: w
                });
            } catch (e) {}
        };

        // Zmiana rozmiaru kontenera z kontentem podczas zmiany wielkości okna
        // *************************************************************************
        var resizeContent = function() {

            var sidebarWidth = $('body.videogallery .content-object.left-menu').outerWidth(true);
            var minHeight = 440;
            var maxHeight = 1200;

            // max height dla iPad wynosi 670
            if (isMobile || navigator.platform.indexOf('iPad') != -1) {
                maxHeight = 670;
            }
            $('#videogallerygrid').hide();
            $('#videogallerygrid').css('width', $(document).width() - sidebarWidth - 15);
            //var h = $(window).height() - 30; // dodajemy 10px pod scrollbarerm
            //var h = $('body').height() - 30 - +$( '.main-header' ).outerHeight(true) - +$( '.navs' ).outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            var h = $(document).height() - 30 - +$('.main-header').outerHeight(true) - +$('.navs').outerHeight(true) - 10; // dodajemy 10px pod scrollbarerm
            // Returns height of browser viewport
            $('#videogallerygrid').show();

            // Returns height of HTML document
            if (h > maxHeight) h = maxHeight;
            else if (h < minHeight) h = minHeight;
            //$('.jspPane,#itemList').height(h);

            $('.jspContainer,#videogallerygrid .content-object.objects').height(h + 20);
            if (myJsp) {
                var tmp = myJsp.getPercentScrolledX();
            }

            setUlWidth(h);
            positionFooter();

            if (myJsp) {
                myJsp.scrollToPercentX(tmp, false);
            }
        };

        // wypozycjonowanie stopki
        // *************************************************************************
        var positionFooter = function() {
            $('body.videogallery .content-object.left-menu').removeAttr('style');
            $('body.videogallery .content-object.left-menu li.bottom').removeAttr('style');
            if ($('body.videogallery .content-object.left-menu').height() < $('#videogallerygrid .content-object.objects').height()) {
                $('body.videogallery .content-object.left-menu').height($('#videogallerygrid .content-object.objects').height());
                var offset = 30;
                $('body.videogallery .content-object.left-menu li.bottom').css({
                    position: 'absolute',
                    top: $('body.videogallery .content-object.left-menu').height() - offset
                });
            } else {
                $('body.videogallery .content-object.left-menu').removeAttr('style');
                $('body.videogallery .content-object.left-menu li.bottom').removeAttr('style');
            }
        };

        // Inicjalizacja jScrollPane
        // *************************************************************************

        /*myJsp = $('#videogallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        }).data('jsp');*/
        var jScroll = $('#videogallerygrid .content-object.objects').jScrollPane({
            animateDuration: 450
        });
        myJsp = jScroll.data('jsp');
        jScroll.bind('mousewheel',function (event, delta, deltaY){
                myJsp.scrollByX(delta*-50);
                return false;
            }
        );

        // bind to window resize event
        // *************************************************************************
        var isMobile = typeof(isMobile) != 'undefined' ? isMobile : false;
        if (!isMobile) {
            $(window).unbind('resize', resizeContent).bind('resize', resizeContent).trigger('resize');
        }

        // Ladowanie zdjec
        // *************************************************************************
        //$('#videogallerygrid .content-object.objects li:eq(0)').each(Tools.Videos.SequentialLoader);
    }





    $(".fancybox").fancybox({
        openEffect: 'none',
        closeEffect: 'none',
        padding: [15, 15, 40, 15],
        'tpl': {
            wrap: '<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div><div class="fancybox-navigation"><a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"></a><a title="Close" class="fancybox-item fancybox-close hide-me" href="javascript:;"></a><a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"></a></div></div></div></div>',
            closeBtn: '',
            next: '',
            prev: ''
        },
        afterLoad: function(current, previous) {
            $('.fancybox-nav.fancybox-prev').on('click', function() {
                $.fancybox.prev();
            });
            $('.fancybox-nav.fancybox-next').on('click', function() {
                $.fancybox.next();
            });
            $('.fancybox-item.fancybox-close.hide-me').on('click', function() {
                $.fancybox.close();
            });


        }
    });
});
