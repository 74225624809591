/*
 * Handler for confirmation init
 */

App.namespace.wrap('shared.captcha_refresh', function () {
    $('.js-captcha-refresh').on('click', function(event) {
		event.preventDefault();
        var captchaImg = $('.captcha-box img'),
            time = moment().milliseconds(),
            captchaSrc = captchaImg.prop('src'),
            refreshElement = $(this);

        captchaImg.prop('src', captchaSrc + time);

        $({deg: 0}).animate({deg: -360}, {
            duration: 1000,
            step: function(now) {
                refreshElement.css({
                    transform: 'rotate(' + now + 'deg)'
                });
            }
        });
    });
});
