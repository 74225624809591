(function(exports) {
    exports.App = exports.App || {};

    var merge = function(results, object) {
        var key;

        for(key in object) {

            if(object.hasOwnProperty(key)) {
                results[key] = object[key];
            }
        }

        return results;
    }

    /**
     * Namespace generator
     *
     * @author Stefan Matsson
     * @see http://jsfiddle.net/jG3Lt/
     * @param string ns namespace identificator
     * @param function factory returned value will be assigned to the namespace
     */
    var namespace = function(ns, factory) {
        var parts = ns.split('.'),
            current = parts.shift();

        if (parts.length === 0) {
            this[current] = (undefined === this[current]) ? factory() : merge(factory(), this[current]);

            return this;
        }

        if (this[current] === void 0) {
            this[current] = {};
        }

        namespace.call(this[current], parts.join('.'), factory);

        return this;
    }

    /**
     * Wrap callback into a factory function.
     *
     * All arguments passed to factory will be passed to callback.
     *
     * @example namespace.wrap('foo.bar', function() { console.log(arguments); });
     *          foo.bar('arg1', 'arg2');
     *
     * @param string ns
     * @param function callback
     */
    namespace.wrap = function(ns, callback) {
        var factory = function() {

            return function() {
                return callback.apply(exports, arguments);
            }
        }

        namespace.apply(this, [ns, factory]);
    }.bind(exports.App);

    /**
     * Retrieve from namespace
     *
     * @param string ns
     * @return mixed
     */
    namespace.get = function(ns) {
        var parts = ns.split('.'),
            current = this,
            key;

        do {
            key = parts.shift()

            if(!current[key]) {
                return void 0;
            }

            current = current[key];
        }
        while(parts.length)

        return current;
    }.bind(exports.App);

    exports.App.namespace = namespace;
})(this);