/*
 * Handler for checkbox init
 */

App.namespace.wrap('shared.checkbox', function () {
    $('[data-checkbox=small-all]').checkboxpicker({
        offLabel: false,
        onLabel: false,
        offIconClass: 'glyphicon glyphicon-unchecked',
        onIconClass: 'glyphicon glyphicon-check',
        style: 'btn-group-xs'
    }).change(function(evt) {
        if (this.checked) {
            $('form.selectall_form').append('<input type="hidden" name="selectall_data_ids[]" class="selectall_data_ids" value="'+$(this).val()+'" />');
        } else {
            $('input.selectall_data_ids[value='+$(this).val()+']').remove();
        }
        if($('input:checkbox[name="selectall_ids"]:checked').length<=0){
            $('[data-checkbox=selectall]').prop('checked', false);
        }
        if($('input:checkbox[name="selectall_ids"]:checked').length==$('input:checkbox[name="selectall_ids"]').length){
            $('[data-checkbox=selectall]').prop('checked', true);
        }

    });
    $('[data-checkbox=selectall]').checkboxpicker({
        offLabel: false,
        onLabel: false,
        offIconClass: 'glyphicon glyphicon-unchecked',
        onIconClass: 'glyphicon glyphicon-check',
        style: 'btn-group-xs'
    }).change(function(evt) {
        if (this.checked) {
            $('[data-checkbox=selectall]').prop('checked', true);
            $('input:checkbox[name="selectall_ids"]').prop('checked', true);
            $('input:checkbox[name="selectall_ids"]:checked').each(function( index ) {
                if($('input.selectall_data_ids[value='+$(this).val()+']').length<=0){
                    $('form.selectall_form').append('<input type="hidden" name="selectall_data_ids[]" class="selectall_data_ids" value="'+$(this).val()+'" />');
                }
            });
        } else {
            $('[data-checkbox=selectall]').prop('checked', false);
            $('input:checkbox[name="selectall_ids"]').prop('checked', false);
            $('input.selectall_data_ids').remove();
        }
    });
});
