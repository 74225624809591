/*
 * Handler for confirmation init
 */

App.namespace.wrap('shared.showmodal', function (options) {
    /*var modal = $('<div class="modal fade" id="modal-layer" tabindex="-1" role="dialog" aria-hidden="true"><div class="modal-dialog modal-lg"><div class="loader"><img src="/images/loader.gif" alt=""></div><div class="modal-content"></div></div></div>');
    // Modal element
    var modal_content = shared.Modal.getDialogElement().find('.modal-content');
    $('.showmodal').on('click', function() {
        // Get the record's ID via attribute
        var id = $(this).attr('data-record-id');
        var url = $(this).attr('data-record-url');
        var modal = $('body #modalData');
        if(modal.length<=0){
            $('body').append($('div').attr('id','modalData'));
        }
        $.ajax({
            url: url,
            method: 'GET'
        }).success(function(response) {
            // Show the dialog
            bootbox
                .on('shown.bs.modal', function() {
                })
                .on('hide.bs.modal', function(e) {
                })
                .modal('show');
        });
    });*/

    // Create jQuery body object
    var $body = $('body'),

    // Use a tags with 'class="modalTrigger"' as the triggers
    $modalTriggers = $('.modalTrigger'),

    // Trigger event handler
    openModal = function(evt) {
        var $trigger = $(this),                  // Trigger jQuery object

        modalPath = $trigger.attr('data-href'),       // Modal path is href of trigger

        $newModal,                               // Declare modal variable

        removeModal = function(evt) {            // Remove modal handler
            $newModal.off('hidden.bs.modal');  // Turn off 'hide' event
            $newModal.remove();                // Remove modal from DOM
        },

        showModal = function(data) {             // Ajax complete event handler
            $body.append(data);                // Add to DOM
            $newModal = $('.modal').last();    // Modal jQuery object
            $newModal.modal('show');           // Showtime!
            App.shared.confirmation();
            App.shared.popover();
            App.product.form($newModal);
            App.product.prfecalc();
            $newModal.on('hidden.bs.modal',removeModal); // Remove modal from DOM on hide
        };

        $.get(modalPath,showModal);             // Ajax request

        evt.preventDefault();                   // Prevent default a tag behavior
    };
    $modalTriggers.off('click');
    $modalTriggers.on('click',openModal);
});
